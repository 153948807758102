import React, { useState, useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Styles from './CreateQuiz.module.css';
import { MultiSelectBoxNew } from '../../components/SelectBox/MultiSelectBox';
import DeleteCriteria from './Modals/DeleteCriteria';
import { getCriteriaOptions, getCriteriaType, getQuestionCriteriaByType } from '../../redux/actions/quizActions';

const CreateNewQuiz = ({
    criteriaTypeList,
    criteriaTypes,
    setCriteriaTypes,
    setCriteriaTypeList,
    setSelectedFrequency,
    productsCategory,
    setSelectedCategory,
    selectedCategory,
    selectedFrequency,
    setCriteriaSelectType,
    criteriaSelectType,
    setSelectedProduct,
    criteriaArrays,
    setCriteriaArrays,
    selectedProduct,
    handleSubmitQuiz,
    handleCancelClick
}) => {
    const dispatch = useDispatch();
    const [showModal, setShowModal] = useState(false);
    const [criteriaIndexToDelete, setCriteriaIndexToDelete] = useState(null);
    const userLogin = useSelector(state => state.userLogin);

    const [availableCriteriaTypes, setAvailableCriteriaTypes] = useState([]);

    const frequencies = [
        { label: '3 months' },
        { label: '6 months' },
        { label: '9 months' },
        { label: '12 months' },
    ];

    const getQuestioncriteriaOptions = useCallback(async () => {
        if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
            const data = await dispatch(getCriteriaType(userLogin));
            if (data?.data) {
                setCriteriaTypes(data?.data);
            }
        }
    }, [userLogin, dispatch]);

    // const getQuestioncriteriaByType = useCallback(async (type, index) => {
    //     if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
    //         const data = await dispatch(getQuestionCriteriaByType(userLogin, type));
    //         if (data) {

    //             setCriteriaTypeList(prevList => {
    //                 const updatedList = [...prevList];
    //                 updatedList[index] = { ...updatedList[index], multiSelect: data.multiSelect };
    //                 return updatedList;
    //             });

    //             setCriteriaArrays(prevArrays => {
    //                 const updatedArrays = [...prevArrays];
    //                 updatedArrays[index] = data?.data?.map(item => ({
    //                     value: item.displayText,
    //                     label: item.displayText
    //                 }));
    //                 return updatedArrays;
    //             });
    //             setCriteriaSelectType(prevTypes => {
    //                 const updatedTypes = [...prevTypes];
    //                 updatedTypes[index] = data.multiSelect;
    //                 return updatedTypes;
    //             });
    //         }
    //     }
    // }, [userLogin, dispatch]);

    const getQuestioncriteriaByType = useCallback(async (type, index) => {
        if (userLogin && userLogin.userInfo && userLogin.userInfo.token) {
           
            setCriteriaTypeList(prevList => {
                const updatedList = [...prevList];
                updatedList[index] = { ...updatedList[index], criteria: [] }; 
                return updatedList;
            });
    
            const data = await dispatch(getQuestionCriteriaByType(userLogin, type));
            if (data) {
                setCriteriaArrays(prevArrays => {
                    const updatedArrays = [...prevArrays];
                    updatedArrays[index] = data?.data?.map(item => ({
                        value: item.displayText,
                        label: item.displayText
                    }));
    
                    if (data.multiSelect) {                   
                        updatedArrays[index] = [
                            { value: 'All', label: 'All' },
                            ...updatedArrays[index]
                        ];
                    }
    
                    return updatedArrays; 
                });
                setCriteriaSelectType(prevTypes => {
                    const updatedTypes = [...prevTypes];
                    updatedTypes[index] = data.multiSelect;
                    return updatedTypes;
                });
            }
        }
    }, [userLogin, dispatch]);


    useEffect(() => {
        getQuestioncriteriaOptions();
    }, [getQuestioncriteriaOptions]);

    const addCriteria = useCallback(() => {
        setCriteriaTypeList(prevList => {
            const newList = [...prevList, { type: '', criteria: [] }];
            setCriteriaArrays(prevArrays => [...prevArrays, []]);
            setCriteriaSelectType(prevTypes => [...prevTypes, true]);
            return newList;
        });
    }, [setCriteriaTypeList]);

    const removeCriteria = useCallback((index) => {
        setCriteriaTypeList(prevList => prevList.filter((_, i) => i !== index));
        setCriteriaArrays(prevArrays => prevArrays.filter((_, i) => i !== index));
        setCriteriaSelectType(prevTypes => prevTypes.filter((_, i) => i !== index));
    }, [setCriteriaTypeList]);

    // useEffect(() => {
    //     const usedTypes = new Set(criteriaTypeList.map(item => item.type));
    //     const filteredTypes = criteriaTypes?.filter(type => !usedTypes.has(type.name));
    //     setAvailableCriteriaTypes(filteredTypes);
    // }, [criteriaTypeList, criteriaTypes]);

    const handleSingleSelectChange = useCallback((index, value) => {
        setCriteriaTypeList(prevList => {
            const updatedList = [...prevList];
            updatedList[index] = { ...updatedList[index], type: value };
            return updatedList;
        });
        getQuestioncriteriaByType(value, index);
    }, [setCriteriaTypeList, getQuestioncriteriaByType]);

    // const handleSelectChange = useCallback((index, value) => {
    //     setCriteriaTypeList(prevList =>
    //         prevList.map((item, i) =>
    //             i === index ? { ...item, criteria: value } : item
    //         )
    //     );
    // }, [setCriteriaTypeList]);

    const handleSelectChange = useCallback((index, value) => {       
        const selectedValues = Array.isArray(value) ? value : [value];    
       
        const allCriteria = criteriaArrays[index] || []; 
        const includesAll = selectedValues.some(item => item.label === 'All'); 
    
        setCriteriaTypeList(prevList => 
            prevList.map((item, i) => {
                if (i === index) {
                    if (includesAll) {                    

                        return { ...item, criteria: allCriteria.filter(option => option.label !== 'All') }; 
                    } else {                                  
                        return { ...item, criteria: selectedValues }; 
                    }
                }
                return item; 
            })
        );
    
        if (!includesAll) {
            setCriteriaTypeList(prevList =>
                prevList.map((item) =>
                    item.criteria.includes(allCriteria.find(c => c.label === 'All')?.value) ? { ...item, criteria: [] } : item 
                )
            );
        }
    }, [setCriteriaTypeList, criteriaArrays]);
 

    const handleShowModal = (index) => {
        setCriteriaIndexToDelete(index);
        setShowModal(true);
    };

    const handleModalClose = () => {
        setShowModal(false);
        setCriteriaIndexToDelete(null);
    };

    const handleDeleteCriteria = () => {
        if (criteriaIndexToDelete !== null) {
            removeCriteria(criteriaIndexToDelete);
            handleModalClose();
        }
    };

    return (
        <>
            <div className={Styles.mainContent}>
                <div className={Styles.mainDropdownSection}>
                    <div className={Styles.categoryDropdown}>
                        <span className={Styles.dropdownLabel}>Product Category</span>
                        <div className={Styles.filter__input_top}>
                            <select
                                className={Styles.filter__input__select}
                                id="productsCategory"
                                name="productsCategory"
                                value={selectedCategory?.value}
                                onChange={(e) => {
                                    e.preventDefault();
                                    let productName = productsCategory.find((item) => item.productId === e.target.value)?.productName;
                                    setSelectedProduct({ title: productName, value: e.target.value });
                                    setSelectedCategory((prev) => ({...prev, value: e.target.value, title: productName }));
                                }}
                            >
                                <option value="">Select Product</option>
                                {productsCategory?.map((item, index) => (
                                    <option key={index} value={item.productId}>
                                        {item.productName}
                                    </option>
                                ))}
                            </select>
                            {/* <select
                                className={Styles.filter__input__select}
                                name="products"
                                id="products"
                                value={selectedCategory.value}
                                onChange={(e) => setSelectedFrequency((prev) => ({ ...prev, value: e.target.value }))}
                                >
                                    <option value="">Select Product</option>
                                {productsCategory?.map((item, index) => (
                                    <option key={index} value={item.productId}>
                                        {item.productName}
                                    </option>
                                ))}
                                </select> */}
                        </div>
                        {selectedProduct.error && <span className={Styles.err_msg}>{selectedProduct.error}</span>}
                    </div>
                    <div className={Styles.categoryDropdown}>
                        <span className={Styles.dropdownLabel}>Select Frequency</span>
                        <div className={Styles.filter__input_top}>
                            <select
                                className={Styles.filter__input__select}
                                name="frequency"
                                id="frequency"
                                value={selectedFrequency.value}
                                onChange={(e) => setSelectedFrequency((prev) => ({ ...prev, value: e.target.value }))}
                            >
                                <option value="">Select Frequency</option>
                                {frequencies.map((item, index) => (
                                    <option key={index} value={item.label}>
                                        {item.label}
                                    </option>
                                ))}
                            </select>
                        </div>
                        {selectedFrequency.error && <span className={Styles.err_msg}>{selectedFrequency.error}</span>}
                    </div>
                </div>
                <div className={Styles.addCriteriaBtn}>
                    <button onClick={addCriteria} className={Styles.header__container_btn}>
                        <p>
                            <svg className="header__container--icon">
                                <use xlinkHref="/sprite.svg#icon-plus" />
                            </svg>
                        </p>
                        <span>Add Criteria</span>
                    </button>
                </div>
                {criteriaTypeList?.map((item, i) => (
                    <div key={i} className={Styles.mainDropdownSection1}>
                        <div className={Styles.criteriaDropdownSection}>
                            <div className={Styles.categoryDropdown}>
                                <span className={Styles.dropdownLabel1}>Select Criteria Type</span>
                                <div className={Styles.filter__input_top3}>
                                    <select
                                        className={Styles.filter__input__select1}
                                        value={item.type || ''}
                                        onChange={(e) => handleSingleSelectChange(i, e.target.value)}
                                    >
                                        <option value="">Select Criteria Type</option>
                                        {criteriaTypes?.filter((type) =>
                                            !criteriaTypeList.map((ctl) => ctl.type).filter((ctl) => ctl !== item.type).includes(type.name)
                                        )?.map((type, index) => (
                                            <option key={index} value={type.name}>
                                                {type.displayText}
                                            </option>
                                        ))}
                                    </select>
                                </div>
                            </div>
                            <div className={Styles.categoryDropdown}>
                                <span className={Styles.dropdownLabel1}>Set Criteria</span>
                                <MultiSelectBoxNew
                                    multiple={criteriaSelectType[i] !== false} // Set to true by default if not specified
                                    containerStyle={{ width: '35rem' }}
                                    icon={false}
                                    value={item.criteria}
                                    dontshowLabel
                                    onChange={(value) => handleSelectChange(i, value)}
                                    errorMessage={''}
                                    label={'Set Criteria'}
                                    children={criteriaArrays[i] || []}
                                />
                            </div>
                        </div>
                        <div>
                            <button
                                className="table__button table__button--delete"
                                style={{ background: 'red' }}
                                onClick={() => handleShowModal(i)}
                            >
                                <svg className="table__button--icon-red" style={{ fill: 'white' }}>
                                    <use xlinkHref="/sprite.svg#icon-delete" />
                                </svg>
                                <span>Delete Criteria</span>
                            </button>
                        </div>
                    </div>
                ))}
            </div>
            <div className={Styles.bottomButtonsSection}>
                <div></div>

                <div className={Styles.cancel__button}>
                <button className={Styles.loginContainer__button1} onClick={handleCancelClick}>
					<span className={Styles.loginContainer__button_text}>CANCEL</span>
					<div className={Styles.loginContainer__button__iconBox}>
						<svg className={Styles.loginContainer__button__icon1}>
							<use xlinkHref="/sprite.svg#icon-login" />
						</svg>
					</div>
				</button>
                </div>

                <button className={Styles.loginContainer__button} onClick={handleSubmitQuiz}>
                    <span className={Styles.loginContainer__button_text}>NEXT</span>
                    <div className={Styles.loginContainer__button__iconBox}>
                        <svg className={Styles.loginContainer__button__icon}>
                            <use xlinkHref="/sprite.svg#icon-login" />
                        </svg>
                    </div>
                </button>
                <DeleteCriteria data={{ showModal, onDeleteModalClose: handleModalClose, handleDeleteCriteria }} />
            </div>
        </>
    );
};

export default CreateNewQuiz;
