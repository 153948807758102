import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Content from '../../components/Content';
import Loader from '../../components/Skeletons/Loader';
import { reportDTOActionpApi, reportDTOFooterActionpApi } from '../../redux/actions/assessmentActions';
import './PatientSummary.css';
import SummaryPdf from './SummaryPdfOLD';

const PatientSummary = ({ match }) => {
	const userLogin = useSelector((state) => state.userLogin);

	const [url, setUrl] = useState('');
	const [adUrl, setAdUrl] = useState('');
	const [pdfURL, setPDFUrl] = useState('');

	useEffect(
		() => {
			var assessmentResultId = match.params.userId;
			var isadmin = false;
			var isprintpdf = true;
			reportDTOActionpApi({ assessmentResultId, isadmin, isprintpdf }).then((response) => {
				if (response && response.status) { 
					const adObj = response.adObj;
					setUrl(encodeURI(response.pdfURL));
					setAdUrl(adObj.imageurl);
					setPDFUrl(adObj.prescriptionInfoFile);
				}
			});
			// reportDTOFooterActionpApi({ assessmentResultId }).then((response) => {
			// 	if (response && response.data) {
			// 		setAdUrl(response.data.imageurl);
			// 		setPDFUrl(response.data.prescriptionInfoFile);
			// 	}
			// });
		},
		[match.params.userId]
	);

	return (
		<Content headerTitle='Patient Summary'>
			{url ? (
				<div className='summaryContainer'>
					{//clientId === "0779f084-7187-4353-990c-445a56b56ee2"?
						userLogin && userLogin.userInfo && userLogin.userInfo.clinicid === 1 ? (
							<div className='Summary__width__Container'>
								<iframe
									id='iframeTou'
									title='summary-pdf'
									type='application/pdf'
									src={`${url}#view=fitH`}
									style={{ width: '100%', height: '60vh' }} 
								/>
								<SummaryPdf imageURL={adUrl} pdfURL={pdfURL} />
							</div>
						) : (
							<div className='Summary__width__Container'>
								<iframe
									title='summary-pdf'
									type='application/pdf'
									src={`${url}#view=fitH`}
									style={{ width: '100%', height: '100%' }}
								/>
							</div>
						)}
				</div>
			) : (
				<div className='summaryContainer'>
					<Loader />
				</div>
			)}
		</Content>
	);
};

export default PatientSummary;
